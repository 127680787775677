<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-airplane"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                complete
                complete-icon="mdi-airplane"
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="mt-0 pt-0"
      >
        <v-card class="mt-0 pt-0">
          <v-overlay v-if="loading && !getPurchaseErrors">
            <v-row
              class="mt-12"
            >
              <v-col class="d-flex justify-center">
                <ellipse-progress
                  :loading="loading"
                  :size="180"
                  img-src="src/assets/Travel-Icon.png"
                  thickness="5"
                  empty-thickness="3"
                  line-mode="out 5"
                  legend="false"
                  animation="rs 700 1000"
                  font-size="1.5rem"
                  color="#00A1B7"
                  empty-color-fill="transparent"
                  filename="Travel-Icon.png"
                >
                </ellipse-progress>
              </v-col>
            </v-row>
          </v-overlay>
          <div v-if="!loading && !getPurchaseErrors">
            <v-row>
              <v-col class="d-inline-flex align-center mt-16 justify-center">
                <v-img
                  src="/img/celebration 1.svg"
                  :height="mobileDevice ? '40px' : '64px'"
                  :max-width="mobileDevice ? '40px' : '64px'"
                  class="mr-4"
                ></v-img>
                <div :class="mobileDevice ? 'title-text-mobile' : 'title-text'">
                  Congratulations!
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="title-subtext">
                  Thank you for your purchase.
                </p>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                sm="11"
                md="11"
                lg="10"
              >
                <v-card elevation="0">
                  <div v-show="!mobileDevice">
                    <v-row class="grey-background grey-outline table-fields">
                      <v-col cols="3">
                        Provider
                      </v-col>
                      <v-col cols="3">
                        Product
                      </v-col>
                      <v-col cols="3">
                        Premium Cost
                      </v-col>
                      <v-col cols="3">
                        Policy No.
                      </v-col>
                    </v-row>
                    <v-row class="grey-outline table-fields">
                      <v-col cols="3">
                        GENERALI
                      </v-col>
                      <v-col cols="3">
                        {{ policy.product[0] }}
                      </v-col>
                      <v-col cols="3">
                        <v-chip class="primary">
                          <span
                            class="table-fields white--text"
                          >${{ price }}</span>
                        </v-chip>
                      </v-col>
                      <v-col cols="3">
                        {{ policyNumber }}
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-btn
                          elevation="0"
                          icon
                          large
                          dark
                          color="primary"
                        >
                          <v-icon dark>
                            mdi-email
                          </v-icon>
                        </v-btn>
                        <v-btn
                          elevation="0"
                          icon
                          large
                          dark
                          color="primary"
                        >
                          <v-icon dark>
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </div>
                  <div v-if="mobileDevice">
                    <v-row class="table-fields d-flex justify-center">
                      <v-col
                        cols="11"
                        class="grey-background grey-outline"
                      >
                        <v-col cols="12">
                          Provider: GENERALI
                        </v-col>
                        <v-col cols="12">
                          Product : {{ policy.product[0] }}
                        </v-col>
                        <v-col cols="12">
                          Premium Cost :
                          <v-chip class="primary">
                            <span
                              class="table-fields white--text"
                            >${{ price }}</span>
                          </v-chip>
                        </v-col>
                        <v-col cols="12">
                          Policy No. : {{ policyNumber }}
                        </v-col>
                        <!-- <v-col cols="12">
                          Actions : <v-btn
                            elevation="0"
                            icon
                            large
                            dark
                            color="primary"
                          >
                            <v-icon dark>
                              mdi-email
                            </v-icon>
                          </v-btn>
                          <v-btn
                            elevation="0"
                            icon
                            large
                            dark
                            color="primary"
                          >
                            <v-icon dark>
                              mdi-download
                            </v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                cols="11"
                class="d-flex justify-center"
              >
                <div class="policy-txt">
                  This policy has been added to Your Blanket Account. You can
                  view all of your policies under
                  <a
                    class="policy-txt primary--text"
                    @click="
                      toUserPolcies();
                      clickedFieldGlobal(
                        'viewUserPolicies',
                        102,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    My Policies.
                  </a>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="mobileDevice ? 'justify-center' : 'justify-end pa-6'"
                class="d-flex"
              >
                <v-btn
                  large
                  right
                  class="primary font-weight-bold mt-4 mb-2"
                  to="/userpolicies"
                  @click="
                    clickedFieldGlobal(
                      'viewUserPolicies',
                      103,
                      'American Travel Survey v1'
                    )
                  "
                >
                  View My Policies
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import idleMixin from '../../../mixins/IdleMixin'
  export default {
    mixins: [idleMixin],
    data () {
      return {}
    },
    computed: {
      getPurchaseErrors () {
        let message = ''
        message = this.$store.getters.getPurchaseErrors
        // if (this.$store.getters.getPurchaseErrors[0]) {
        //   this.$store.getters.getPurchaseErrors.forEach(entry => {
        //     message += entry + ' \n'
        //   })
        // }
        console.log('purchase error is ', message)
        return message
      },
      finalPrice () {
        return this.coverageDetails.price
      },
      coverageDetails () {
        return this.$store.getters.getTravelCoverageDetails
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      loading () {
        return this.$store.getters.getloading
      },
      policy () {
        return this.$store.getters.getTravelUSAPolicy.purchaseresponse
      },
      owner () {
        return this.$store.getters.get
      },
      price () {
        console.log('price ', this.policy.price[0])
        return this.policy.price[0]
      },
      policyNumber () {
        return this.policy.policynumber[0]
      },
    },
    mounted () {
      this.$store.commit('setIsPolicyPurchased', false)
      localStorage.removeItem('redirectUrl')
    },
    methods: {
      goBack () {
        this.$gtag.event('TravelPoliciesUsa', { action: 'goBackToSelectPlan' })
        this.$router.push({ path: '/travel/selectplanusa' })
      },
      toUserPolcies () {
        this.$router.push('/userpolicies')
      },
    },
  }
</script>
<style lang="scss" scoped>
.table-fields {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.table-fields-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.title-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.title-text-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.grey-background {
  background-color: #f5f5f5;
}

.grey-outline {
  border: 1px solid #e0e0e0;
}

.title-subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #212121;
}

.policy-txt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 18px */

  letter-spacing: -0.04em;

  /* Gray/600 */

  color: #757575;
}
</style>
